import {
  IonAlert,
  IonButton,
  IonButtons,
  IonChip,
  IonContent,
  IonHeader,
  IonItemGroup,
  IonLoading,
  IonModal,
  IonNote,
  IonText,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import React, { useEffect, useMemo, useState, useRef } from "react";
import {
  InputCheckbox,
  InputDate,
  InputNumber,
  InputSelection,
  InputText,
} from "../../components/FormInputType";
//   import DepartmentSelect from "../../components/Select/DepartmentSelect";
//   import EmployeeSelect from "../../components/Select/EmployeeSelect";
//   import MinistrySelect from "../../components/Select/MinistrySelect";
import { dataAPI } from "./api";
import { modelData } from "./model";
import { IEmployee, IUserGroup } from "models/employee.model";
import { IUser } from "models/user.model";
import { IJiraProject } from "models/dropdown.model";
import { selectEmpList } from "redux/employee/selector";
import { useAppSelector } from "redux/hooks";
import DepartmentSelect from "components/Select/DepartmentSelect";
import {
  IonCheckbox,
  IonDatetime,
  IonInput,
  IonItem,
  IonLabel,
  IonSelect,
  IonSelectOption,
  IonTextarea,
  IonCol,
} from "@ionic/react";
import { fullLabelStyle } from "variable";
import {
  selectDDLUserGroup,
  selectJiraProjects,
  selectUserGroupList,
} from "redux/dropdown/reducer";
import { selectAuthen } from "redux/authen/selector";
import EmployeeSelect, { EmpChip } from "components/Select/EmployeeSelect";
import { formatSprintName } from "utils";
import ProjectSelect from "components/Select/ProjectSelect";
// import { group } from "console";
// import { head } from "lodash";

const ownersLokup = [
  {
    empNames: [
      "amorn.ch",
      "atthavit.tu",
      "pawnwipa.me",
      "peeradon.sa",
      "patipan.sa",
      "punsapach.bu",
      "saranthorn.ph",
    ],
    owners: ["saranthorn.ph", "peeradon.sa", "punsapach.bu"],
  },
  {
    empNames: ["isarapong.ek", "pinphong.su", "thanakorn.th", "varit.fo"],
    owners: ["isarapong.ek"],
  },
  {
    empNames: [
      "artit.sa",
      "juthaporn.vi",
      "kanyawee.po",
      "pairode.ja",
      "patchara.wo",
      "thakdanai.sa",
      "thitiya.tr",
      "napatsakorn.ro",
      "nathanon.th",
    ],
    owners: ["artit.sa", "kanyawee.po"],
  },
];

export const EmpInputSelection = ({
  label,
  name,
  value,
  option,
  onChange,
  ...rest
}: any) => {
  return (
    <IonItem lines="inset">
      <IonLabel position="fixed" color="primary" style={fullLabelStyle}>
        {label}
      </IonLabel>
      <IonSelect
        slot="end"
        interface="popover"
        value={value}
        name={name}
        onIonChange={(e) => onChange(e)}
        {...rest}
      >
        {(option as IEmployee[]).map((option, i) => (
          <IonSelectOption key={i} value={option.emp_id}>
            {option.username}
          </IonSelectOption>
        ))}
      </IonSelect>
    </IonItem>
  );
};

const Modal: React.FC<{
  buttonLabel: string;
  modal: boolean;
  setModal: Function;
  DropDownList: any;
  isEdit?: boolean;
  defaultValue?: modelData;
  editId?: modelData["id"];
  triggerRefetch: () => void;
}> = ({
  buttonLabel,
  modal,
  setModal,
  DropDownList,
  isEdit = false,
  defaultValue,
  editId,
  triggerRefetch,
}) => {
  const [sprintList, setSprintList] = React.useState([
    { sprint_name: "", sp_amt: 0 },
  ]);
  const [userGroup, setUserGroup] = React.useState<IUserGroup | "all">("all");
  const [Item, setItem] = React.useState({
    requesting_pm: "",
    employee_group: "",
    employee: "",
    sprint_name: "",
    sp_amt: 0,
    resource_owner: "",
    resource_owner_1: "",
    resource_owner_2: "",
    resource_owner_3: "",
    approved_by: "",
    jira_project: "",
  });
  const [iserror, setIserror] = React.useState(false);
  const [showLoading, setShowLoading] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [spErrorText, setSpErrorText] = React.useState<null | string>(null);
  const [empgroupErrorText, setEmpgroupErrorText] = React.useState<
    null | string
  >(null);
  const [empErrorText, setEmpErrorText] = React.useState<null | string>(null);
  const [prjErrorText, setPrjErrorText] = React.useState<null | string>(null);
  const empList = useAppSelector(selectEmpList);
  const jiraProjects = useAppSelector(selectJiraProjects);
  const { user, avatar } = useAppSelector(selectAuthen);
  const userGroupOption = useAppSelector(selectUserGroupList);
  const clickingSelectUserGroupRef = useRef(false);
  const DataService = new dataAPI();

  const headLookupByGroupId = useMemo(() => {
    return userGroupOption.reduce((acc, item) => {
      acc[item.group_id] = item.head;
      return acc;
    }, {});
  }, [userGroupOption]);

  const groupLookupByEmpList = useMemo(() => {
    return empList.reduce((acc, item) => {
      acc[item.username] = item.group;
      return acc;
    }, {});
  }, [empList]);
  // console.debug(
  //   "🚀 | groupLookupByEmpList:",
  //   groupLookupByEmpList[Item.employee]
  // );
  // console.debug("🚀 | empList:", empList);

  const handleResetState = () => {
    setItem((prevState: any) => ({
      requesting_pm: "",
      employee_group: "",
      employee: "",
      sprint_name: "",
      sp_amt: 0,
      resource_owner: "",
      resource_owner_1: "",
      resource_owner_2: "",
      resource_owner_3: "",
      approved_by: "",
      jira_project: "",
    }));
    setEmpErrorText("");
    setEmpgroupErrorText("");
    setSpErrorText("");
    setPrjErrorText("");
  };
  const handleAddSprint = () => {
    setSprintList((prev) => [
      ...prev,
      {
        sprint_name: "",
        sp_amt: 0,
      },
    ]);
  };

  const handleRemoveSprint = () => {
    const list = [...sprintList];
    list.splice(1);
    setSprintList([...list]);
  };

  const handleSelectEmp = (e) => {
    const { name, value } = e.target;
    const group = groupLookupByEmpList[value];
    setUserGroup(group);
    const head = headLookupByGroupId[group];
    const sup = supervisorName(value);
    // console.log(
    //   "🚀 ~ file: modal.tsx:203 ~ handleSelectEmp ~ sup:",
    //   sup,
    //   value
    // );

    setItem((prevState: any) => ({
      ...prevState,
      [name]: value,
      resource_owner_1: sup?.[0],
      resource_owner_2: sup?.[1],
      resource_owner_3: sup?.[2],
      approved_by: head,
    }));
    setEmpErrorText("");
  };

  useEffect(() => {
    if (!isEdit || (isEdit && !defaultValue?.employee)) {
      console.log("call reset state", isEdit, defaultValue);
      setUserGroup("all");
      handleResetState();
    } else if (defaultValue?.employee && isEdit) {
      // console.log("call set item with default value");
      setItem({
        ...defaultValue,
        approved_by: headLookupByGroupId[defaultValue.employee_group],
      });
      setUserGroup(defaultValue.employee_group);
      setSprintList([
        { sprint_name: defaultValue.sprint_name, sp_amt: defaultValue.sp_amt },
      ]);
    }
  }, [isEdit, defaultValue, headLookupByGroupId]);

  // console.debug("🚀 | defaultValue:", defaultValue);
  // console.debug("🚀 | Item:", userGroup, Item);

  const onChange = (e: any) => {
    const { name, value } = e.target;
    setItem((prevState: any) => ({
      ...prevState,
      [name]: value,
    }));
    setPrjErrorText("");
  };

  const supervisorName = (empName: string | null) => {
    if (!empName) return null;
    const owners = ownersLokup.find((obj) =>
      obj.empNames.includes(empName)
    )?.owners;
    return owners ?? null;
    // let owner_1: string, owner_2: string;
    // if (
    //   empName &&
    //   [
    //     "amorn.ch",
    //     "atthavit.tu",
    //     "navavit.po",
    //     "pawnwipa.me",
    //     "patipan.sa",
    //     "saranthorn.ph",
    //   ].includes(empName)
    // ) {
    //   owner_1 = "peeradon.sa";
    //   owner_2 = "punsapach.bu";
    // } else if (
    //   empName &&
    //   ["isarapong.ek", "pinphong.su", "thanakorn.th", "varit.fo"].includes(
    //     empName
    //   )
    // ) {
    //   owner_1 = "nontawit.ch";
    //   owner_2 = "weerapat.sa";
    // } else if (
    //   empName &&
    //   [
    //     "artit.sa",
    //     "juthaporn.vi",
    //     "kittapat.ra",
    //     "nuutthapachr.se",
    //     "pairode.ja",
    //     "patchara.wo",
    //     "thakdanai.sa",
    //     "thitiya.tr",
    //     "napatsakorn.ro",
    //   ].includes(empName)
    // ) {
    //   owner_1 = "patipan.pr";
    //   owner_2 = "kanyawee.po";
    // } else {
    //   return null;
    // }
    // return [owner_1, owner_2];
  };

  const handleSelectSprint = (e, index) => {
    const { name, value } = e.target;
    setSprintList((prev) =>
      prev.map((sp, i) => (i === index ? { ...sp, sprint_name: value } : sp))
    );
    setSpErrorText("");
  };

  const handleSprintSpChange = (e, index) => {
    const { name, value } = e.target;
    setSprintList((prev) =>
      prev.map((sp, i) => (i === index ? { ...sp, sp_amt: value } : sp))
    );
  };

  const onSave = async () => {
    setShowLoading(true);

    const cleanSprintList = sprintList.filter(
      (sp) => Boolean(sp.sprint_name) && Boolean(sp.sp_amt)
    );

    // check
    if (userGroup === "all") {
      setShowLoading(false);
      setEmpgroupErrorText("Employee Group is required");
      return;
    }
    if (!Item.employee) {
      setShowLoading(false);
      setEmpErrorText("Employee is required");
      return;
    }
    if (!Item.jira_project) {
      setShowLoading(false);
      setPrjErrorText("Project is required");
      return;
    }
    if (!cleanSprintList.length) {
      setShowLoading(false);
      setSpErrorText("Sprint & Storypoint is required");
      return;
    } else {
      setSpErrorText(null);
    }

    const addData = cleanSprintList.map((sp) => ({
      requested_dept: "",
      employee_group: userGroup,
      employee: Item.employee,
      sprint_name: sp.sprint_name,
      sp_amt: sp.sp_amt,
      approved_by: Item.approved_by,
      resource_owner: Item.resource_owner,
      resource_owner_1: Item.resource_owner_1,
      resource_owner_2: Item.resource_owner_2,
      resource_owner_3: Item.resource_owner_3,
      jira_project: Item.jira_project,
    }));

    const editData = {
      requested_dept: "",
      employee_group: userGroup,
      employee: Item.employee,
      sprint_name: sprintList[0].sprint_name,
      sp_amt: sprintList[0].sp_amt,
      approved_by: Item.approved_by,
      resource_owner: Item.resource_owner,
      resource_owner_1: Item.resource_owner_1,
      resource_owner_2: Item.resource_owner_2,
      resource_owner_3: Item.resource_owner_3,
      jira_project: Item.jira_project,
    };
    try {
      if (isEdit) {
        await DataService.edit(editId, editData);
        setShowLoading(false);
        setModal(false);
        triggerRefetch();
      } else {
        await DataService.create(addData);
        setShowLoading(false);
        setModal(false);
        triggerRefetch();
      }
    } catch (err: any) {
      if (!err) return;
      const message = err?.response?.data?.message ?? err.message;
      setErrorMessage(
        typeof message === "string" ? message : JSON.stringify(message)
      );
      setIserror(true);
    }
  };

  const label = buttonLabel;
  let title;
  if (label === "add") {
    title = "Add New Item";
  }
  if (label === "edit") {
    title = "Edit Item";
  }

  const renderButton = (sprintList) => {
    if (sprintList.length >= 2) {
      return (
        <IonButton color="danger" onClick={handleRemoveSprint} expand="full">
          DELETE SPRINT
        </IonButton>
      );
    } else {
      return (
        <IonButton color="primary" onClick={handleAddSprint} expand="full">
          ADD SPRINT
        </IonButton>
      );
    }
  };
  // console.log("userGroup", userGroup);

  const form = (
    <IonItemGroup>
      <IonItem onChange={onChange}>
        <IonLabel style={fullLabelStyle}>Requesting</IonLabel>
        <EmpChip value={user?.username} />
      </IonItem>
      <IonItem lines="inset">
        <IonLabel>Employee Group</IonLabel>
        <IonSelect
          name="employee_group"
          value={userGroup}
          onIonBlur={(e) => {
            // console.debug("IonSelect onIonBlur");
            clickingSelectUserGroupRef.current = true;
          }}
          onIonDismiss={(e) => {
            // console.debug("IonSelect onIonDismiss");
            clickingSelectUserGroupRef.current = false;
          }}
          onIonChange={(e) => {
            // console.debug(
            //   "IonSelect onIonChange employee_group",
            //   clickingSelectUserGroupRef.current
            // );
            if (!clickingSelectUserGroupRef.current) return;
            const groupId = e.detail.value;
            setUserGroup(groupId);
            handleResetState();
            const head = headLookupByGroupId[groupId];
            setItem((prev) => ({ ...prev, approved_by: head }));
          }}
          interface="popover"
        >
          {userGroupOption.map(({ name, group_id, head }) => (
            <IonSelectOption value={group_id} key={group_id}>
              {name}
            </IonSelectOption>
          ))}
        </IonSelect>
      </IonItem>
      {empgroupErrorText && (
        <IonNote slot="error" color="danger">
          {empgroupErrorText}
        </IonNote>
      )}
      {/* {userGroup !== "all" && ( */}
      <EmployeeSelect
        label="Employee"
        name="employee"
        value={Item.employee}
        // option={userGroupEmpList}
        filterByGroup={userGroup}
        onChange={handleSelectEmp}
      />
      {/* )} */}
      {empErrorText && (
        <IonNote slot="error" color="danger">
          {empErrorText}
        </IonNote>
      )}
      <ProjectSelect
        label="Project"
        value={Item.jira_project}
        name="jira_project"
        color="primary"
        onChange={onChange}
      />
      {prjErrorText && (
        <IonNote slot="error" color="danger">
          {prjErrorText}
        </IonNote>
      )}
      {sprintList.map((item, index) => {
        return (
          <>
            <InputSelection
              label="Sprint"
              name="sprint_name"
              value={item.sprint_name}
              option={formatSprintName()}
              onChange={(e) => handleSelectSprint(e, index)}
            />
            <InputNumber
              label="Storypoint Amount"
              name="sp_amt"
              value={item.sp_amt}
              onChange={(e) => handleSprintSpChange(e, index)}
            />
          </>
        );
      })}
      {spErrorText && (
        <IonNote slot="error" color="danger">
          {spErrorText}
        </IonNote>
      )}
      {!isEdit && renderButton(sprintList)}

      {/* <EmployeeSelect
        label="Supervisor"
        name="resource_owner"
        value={Item.resource_owner}
        filterByGroup={userGroup}
        onChange={onChange}
      /> */}
      <IonItem onChange={onChange}>
        <IonLabel style={fullLabelStyle}>Supervisor</IonLabel>
        {Item.resource_owner_1 && (
          <>
            <EmpChip value={Item.resource_owner_1} />
          </>
        )}
        {Item.resource_owner_2 && (
          <>
            <EmpChip value={Item.resource_owner_2} />
          </>
        )}
        {Item.resource_owner_3 && (
          <>
            <EmpChip value={Item.resource_owner_3} />
          </>
        )}
      </IonItem>
      <IonItem onChange={onChange}>
        <IonLabel style={fullLabelStyle}>Approved By</IonLabel>
        {Item.approved_by && <EmpChip value={Item.approved_by} />}
      </IonItem>
    </IonItemGroup>
  );

  return (
    <>
      <IonModal isOpen={modal}>
        <IonHeader>
          <IonToolbar color="gbdi">
            <IonTitle>{title}</IonTitle>
            <IonButtons slot="end">
              <IonButton expand="block" onClick={onSave}>
                Save
              </IonButton>
              <IonButton
                expand="block"
                onClick={() => {
                  handleResetState();
                  // setUpdate(Math.random());
                  setModal(false);
                  setSprintList([{ sprint_name: "", sp_amt: 0 }]);
                  clickingSelectUserGroupRef.current = false;
                }}
              >
                Close
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent className="ion-padding">{form}</IonContent>
        <IonAlert
          isOpen={iserror}
          onDidDismiss={() => setIserror(false)}
          cssClass="my-custom-class"
          header={"Error"}
          message={errorMessage + "<br>Please try again"}
          buttons={["OK"]}
        />
        <IonLoading
          cssClass="my-custom-class"
          isOpen={showLoading}
          message={"Saving ..."}
          duration={0}
        />
      </IonModal>
    </>
  );
};

export default Modal;
