/** Icons */

import {
  barChart,
  fileTrayStacked,
  flash,
  folder,
  home,
  man,
  people,
  peopleCircle,
  person,
  pieChart,
} from "ionicons/icons";

/** KPI Component */
import KPI_Director from "pages/KPI/Director_KPI";
import KPI_Employee from "pages/KPI/All_Employee_KPI";
import KPI_E360 from "pages/KPI/Behavior";
import KPI_Department from "pages/KPI/Department_Member_KPI";
import KPI_Final_TScore from "pages/KPI/Final_TScore";
import KPI_Individual from "pages/KPI/Individual_KPI";
import KPI_Project from "pages/KPI/Project_KPI";
import KPI_ProjectMember from "pages/KPI/Project_Member_KPI";
import KPI_ReportKPIGroup from "pages/KPI/Report_KPIGroup";
import KPI_ReportProjectGroup from "pages/KPI/Report_ProjectGroup";
import KPI_Setting from "pages/KPI/Setting_KPI/page";
import { IRoute, IRouteGroup } from "../models/route.model";
/** Page Components */
import KPIRouter from "components/KPI/KPIRouter";
import Profile from "../pages/Profile";
import Project from "../pages/Project/page";
import StaffBorrow from "../pages/Staff_Borrow/page";
import {
  chartBellCurve,
  departmentIcon,
  e360Icon,
  powerbiIcon,
} from "../variable/customIcon";
import GBDi_KPI_Dashboard from "./KPI/Dashboard";
import Home from "./Home";

/** API */

import {
  DepartmentAPI,
  DirectorAPI,
  E360API,
  EmployeeAPI,
  FinalScoreAPI,
  IndividualAPI,
  ProjectAPI,
  ProjectMemberAPI,
  ReportKPIGroupAPI,
  ReportProjectGroupAPI,
  PeriodSettingAPI,
} from "api/KPIAPI";

const routes: IRoute[] = [
  {
    path: "/profile",
    page: "profile",
    icon: null,
    component: Profile,
    layout: "main",
    group: "main-top",
    title: "Profile",
    requirePermission: false,
  },
  {
    path: "/home",
    page: "home",
    icon: home,
    component: Home,
    layout: "main",
    group: "main-top",
    title: "Home",
    requirePermission: false,
  },
  {
    path: "/project",
    page: "project",
    icon: flash,
    component: Project,
    layout: "main",
    group: "project",
    title: "BDI Project List",
    requirePermission: true,
  },
  {
    path: "/staff_borrow",
    page: "staff_borrow",
    icon: peopleCircle,
    component: StaffBorrow,
    layout: "main",
    group: "project",
    title: "Staff Borrow",
    requirePermission: true,
  },
  {
    path: "/kpi/dashboard",
    page: "dashboard_kpi",
    icon: barChart,
    component: GBDi_KPI_Dashboard,
    layout: "main",
    group: "kpi",
    title: "KPI",
    subpage: true,
    requirePermission: true,
  },
  {
    path: "/kpi/indv",
    page: "indv_kpi",
    icon: person,
    component: KPIRouter,
    componentProps: {
      MainComponent: KPI_Individual,
      API: IndividualAPI,
      title: "Individual KPI",
      eventKey: "edit_self",
      eventTitleActive: "Open for Evaluation",
    },
    layout: "main",
    group: "kpi",
    title: "Individual",
    subpage: true,
    requirePermission: true,
    style: { marginLeft: "10px" },
  },
  {
    path: "/kpi/pm",
    page: "project_member_kpi",
    icon: people,
    component: KPIRouter,
    componentProps: {
      MainComponent: KPI_ProjectMember,
      API: ProjectMemberAPI,
      title: "Project Member KPI",
      eventKey: "edit_pl",
      eventTitleActive: "Open for Evaluation",
    },
    layout: "main",
    group: "kpi",
    title: "Project Member",
    subpage: true,
    requirePermission: true,
    style: { marginLeft: "10px" },
  },
  {
    path: "/kpi/prj",
    page: "epic_kpi",
    icon: flash,
    component: KPIRouter,
    componentProps: {
      MainComponent: KPI_Project,
      API: ProjectAPI,
      title: "Project KPI",
      eventKey: "edit_spm",
      eventTitleActive: "Open for Evaluation",
    },
    layout: "main",
    group: "kpi",
    title: "BDI Project",
    subpage: true,
    requirePermission: true,
    style: { marginLeft: "10px" },
  },
  {
    path: "/kpi/e360",
    page: "e360_kpi",
    icon: e360Icon,
    component: KPIRouter,
    componentProps: {
      MainComponent: KPI_E360,
      API: E360API,
      title: "360 KPI",
      eventKey: "edit_360",
      eventTitleActive: "Open for Evaluation",
    },
    layout: "main",
    group: "kpi",
    title: "360 KPI",
    subpage: false,
    requirePermission: true,
    style: { marginLeft: "10px" },
  },
  {
    path: "/kpi/department",
    page: "department_member_kpi",
    icon: departmentIcon,
    component: KPIRouter,
    componentProps: {
      MainComponent: KPI_Department,
      API: DepartmentAPI,
      title: "Division KPI",
      eventKey: "edit_department",
      eventTitleActive: "Open for Evaluation",
    },
    layout: "main",
    group: "kpi",
    title: "Tier-2 (Division)",
    subpage: true,
    requirePermission: true,
    style: { marginLeft: "10px" },
  },
  {
    path: "/kpi/emp",
    page: "employee_kpi",
    icon: man,
    component: KPIRouter,
    componentProps: {
      MainComponent: KPI_Employee,
      API: EmployeeAPI,
      title: "Department KPI",
      eventKey: "edit_empKPI",
      eventTitleActive: "Open for Evaluation",
    },
    layout: "main",
    group: "kpi",
    title: "Tier-3 (Department)",
    subpage: true,
    requirePermission: true,
    style: { marginLeft: "10px" },
  },
  // chang path after BE
  {
    path: "/kpi/dir",
    page: "director_kpi",
    icon: man,
    component: KPIRouter,
    componentProps: {
      MainComponent: KPI_Director,
      API: DirectorAPI,
      title: "Director KPI",
      eventKey: "edit_director",
      eventTitleActive: "Open for Evaluation",
    },
    layout: "main",
    group: "kpi",
    title: "Director",
    subpage: true,
    requirePermission: true,
    style: { marginLeft: "10px" },
  },
  {
    path: "/kpi/setting",
    page: "setting_kpi",
    icon: man,
    component: KPI_Setting,
    layout: "main",
    group: "kpi",
    title: "Setting",
    requirePermission: true,
    style: { marginLeft: "10px" },
  },
  // {
  //   path: "/kpi/report-kpi-group",
  //   page: "summary_kpi",
  //   icon: pieChart,
  //   component: KPIRouter,
  //   componentProps: {
  //     MainComponent: KPI_ReportKPIGroup,
  //     API: ReportKPIGroupAPI,
  //     title: "Report KPI Group",
  //     eventKey: "edit_summary",
  //     eventTitleActive: "Open for Edit",
  //   },
  //   layout: "main",
  //   group: "kpi",
  //   title: "KPI Group Summary",
  //   subpage: true,
  //   requirePermission: true,
  //   style: { marginLeft: "10px" },
  // },
  // {
  //   path: "/kpi/report-prj-group",
  //   page: "summary_epic_kpi",
  //   icon: pieChart,
  //   component: KPIRouter,
  //   componentProps: {
  //     MainComponent: KPI_ReportProjectGroup,
  //     API: ReportProjectGroupAPI,
  //     title: "Report Project Group",
  //     eventKey: "edit_summary",
  //     eventTitleActive: "Open for Edit",
  //   },
  //   layout: "main",
  //   group: "kpi",
  //   title: "Project Group Summary",
  //   subpage: true,
  //   requirePermission: true,
  //   style: { marginLeft: "10px" },
  // },
  // {
  //   path: "/kpi/final_kpi",
  //   page: "employee_kpi",
  //   icon: chartBellCurve,
  //   component: KPIRouter,
  //   componentProps: {
  //     MainComponent: KPI_Final_TScore,
  //     API: FinalScoreAPI,
  //     title: "Final T Score",
  //   },
  //   layout: "main",
  //   group: "kpi",
  //   title: "Final T Score",
  //   subpage: true,
  //   requirePermission: true,
  //   style: { marginLeft: "10px" },
  // },
];

export const routeGroup: Array<IRouteGroup> = [
  {
    title: "Project",
    name: "project",
    path: "",
    icon: flash,
    subpage: null,
    collapse: false,
  },
  {
    title: "Table List",
    name: "table",
    path: "",
    icon: folder,
    subpage: null,
    collapse: true,
  },
  {
    title: "BDI Course Record",
    name: "gbdi_course",
    path: "",
    icon: fileTrayStacked,
    subpage: null,
    collapse: true,
  },
  {
    title: "KPI",
    name: "kpi",
    path: "",
    icon: barChart,
    subpage: null,
    collapse: false,
  },
  {
    title: "Logout",
    name: "main-bottom",
    path: "",
    icon: null,
    subpage: null,
    collapse: false,
  },
];

export default routes;
