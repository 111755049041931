import React, { useEffect, useState } from "react";
// import { dataAPI } from "./api";
// import Modal from "./modal";
import { ISettingPeriodData } from "models/kpi.model";
import MaUTable from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { Checkbox, Paper, TablePagination } from "@material-ui/core";

const Table: React.FC<{
  Items: Array<ISettingPeriodData>;
  updateItems: Function;
  username: any;
}> = ({ Items, updateItems, username }) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  useState<Array<ISettingPeriodData>>(Items);

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    column: string,
    rowId: number
  ) => {
    const updatedItems = Items.map((item) => {
      if (item.id === rowId) {
        // Update the specific checkbox value
        return {
          ...item,
          [column]: event.target.checked,
        };
      }
      return item;
    });

    updateItems(updatedItems); // ส่งค่าใหม่ไปให้พ่อแม่ (parent component)
    console.log("👻 ~ updatedItems:", updatedItems);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const columns = React.useMemo(() => {
    return [
      // {
      //   Header: "ID",
      //   accessor: "id",
      // },
      {
        Header: "Period",
        accessor: "period",
      },
      // {
      //   Header: "Start Date",
      //   accessor: "start_date",
      //   Cell: DateCell,
      // },
      // {
      //   Header: "End Date",
      //   accessor: "end_date",
      //   Cell: DateCell,
      // },
      // {
      //   Header: "KPI Group Summary",
      //   accessor: "edit_summary",
      // },
      {
        Header: "Individual",
        accessor: "edit_self",
      },
      {
        Header: "Tier-1",
        accessor: "edit_pl",
      },
      {
        Header: "Tier-2",
        accessor: "edit_department",
      },
      {
        Header: "Tier-3",
        accessor: "edit_empKPI",
      },
      {
        Header: "Director",
        accessor: "edit_director",
      },
      {
        Header: "360 KPI",
        accessor: "edit_360",
      },
      {
        Header: "Display",
        accessor: "is_display",
      },
      // {
      //   Header: "BDI Project",
      //   accessor: "edit_spm",
      // },
      // {
      //   Header: "Default KPI Dashboard",
      //   accessor: "default_indv",
      // },
      // {
      //   Header: "Default Director",
      //   accessor: "default_director",
      // },
      {
        Header: "Display Director",
        accessor: "display_director",
      },
    ];
  }, []);

  const sortedItems = [...Items].sort((a, b) => b.id - a.id);

  return (
    <>
      <TableContainer component={Paper}>
        <MaUTable className="ion-no-margin" aria-label="a dense table">
          <TableHead>
            <TableRow style={{ backgroundColor: "#f5f5f5" }}>
              {columns.map((column) => (
                <TableCell key={column.accessor} style={{ fontWeight: "bold" }}>
                  {column.Header}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedItems
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => {
                return (
                  <TableRow key={row.id}>
                    {/* <TableCell>{row.id}</TableCell> */}
                    <TableCell>{row.name}</TableCell>
                    <TableCell padding="checkbox" align="center">
                      <Checkbox
                        color="primary"
                        checked={row.edit_self}
                        onChange={(e) => handleChange(e, "edit_self", row.id)}
                      />
                    </TableCell>
                    <TableCell padding="checkbox" align="center">
                      <Checkbox
                        color="primary"
                        checked={row.edit_pl}
                        onChange={(e) => handleChange(e, "edit_pl", row.id)}
                      />
                    </TableCell>
                    <TableCell padding="checkbox" align="center">
                      <Checkbox
                        color="primary"
                        checked={row.edit_department}
                        onChange={(e) =>
                          handleChange(e, "edit_department", row.id)
                        }
                      />
                    </TableCell>
                    <TableCell padding="checkbox" align="center">
                      <Checkbox
                        color="primary"
                        checked={row.edit_empKPI}
                        onChange={(e) => handleChange(e, "edit_empKPI", row.id)}
                      />
                    </TableCell>
                    <TableCell padding="checkbox" align="center">
                      <Checkbox
                        color="primary"
                        checked={row.edit_director}
                        onChange={(e) =>
                          handleChange(e, "edit_director", row.id)
                        }
                      />
                    </TableCell>
                    <TableCell padding="checkbox" align="center">
                      <Checkbox
                        color="primary"
                        checked={row.edit_360}
                        onChange={(e) => handleChange(e, "edit_360", row.id)}
                      />
                    </TableCell>
                    <TableCell padding="checkbox" align="center">
                      <Checkbox
                        color="primary"
                        checked={row.is_display}
                        onChange={(e) => handleChange(e, "is_display", row.id)}
                      />
                    </TableCell>
                    {/* <TableCell padding="checkbox">
                    <Checkbox color="primary" checked={row.default_indv} />
                  </TableCell> */}
                    {/* <TableCell padding="checkbox">
                    <Checkbox color="primary" checked={row.default_director} />
                  </TableCell> */}
                    <TableCell padding="checkbox" align="center">
                      <Checkbox
                        color="primary"
                        checked={row.display_director}
                        onChange={(e) =>
                          handleChange(e, "display_director", row.id)
                        }
                      />
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </MaUTable>
      </TableContainer>

      <TablePagination
        rowsPerPageOptions={[10, 15, 20]}
        component="div"
        count={Items.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );
};

export default Table;
