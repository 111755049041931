import {
  IonAvatar,
  IonButton,
  IonButtons,
  IonChip,
  IonCol,
  IonFooter,
  IonGrid,
  IonIcon,
  IonItem,
  IonLabel,
  IonRow,
  IonText,
  IonToolbar,
  IonAccordion,
  IonList,
} from "@ionic/react";
import Collapse from "@material-ui/core/Collapse";
import { EmpChip } from "components/Select/EmployeeSelect";
import {
  bookmark,
  bug,
  chevronDown,
  chevronUp,
  checkbox,
  listCircle,
  person,
  save,
} from "ionicons/icons";
import React, { useEffect, useState } from "react";
import { selectEmpAvatar } from "redux/employee/selector";
import { useAppSelector } from "redux/hooks";
import { roundTwoDigit } from "utils";
import "./ItemKPI.scss";

export const EmpGroup: React.FC<{
  item: any;
  filledCount: { filled: number; all: number };
  empScore: { pl_acm: number | null };
}> = ({ children, item, filledCount, empScore }) => {
  const avatarEmp = useAppSelector(selectEmpAvatar(item.emp_id));

  return (
    <IonAccordion>
      <IonItem id="emp-header" lines="inset" slot="header">
        {item.emp_id ? (
          <>
            <IonAvatar slot="start">
              <img src={avatarEmp} alt="avatar" width="250px" height="250px" />
            </IonAvatar>
            <IonLabel color="dark">
              <h2>{item.emp_id}</h2>
            </IonLabel>
            <IonIcon slot="end" style={{ fontSize: "15px" }} />
          </>
        ) : (
          <>
            <IonIcon slot="start" icon={person} />
            <IonLabel color="dark">
              <h2>Unknowned</h2>
            </IonLabel>
            <IonIcon slot="end" style={{ fontSize: "15px" }} />
          </>
        )}
        <IonChip color="success" aria-disabled>
          {Math.round(item.total_storypoint * 100) / 100} Storypoint
        </IonChip>
        <IonChip color="primary" aria-disabled>
          {`${filledCount.filled}/${filledCount.all}`}
        </IonChip>
        <IonChip color="purple" aria-disabled>
          {`PM : ${empScore.pl_acm ? roundTwoDigit(empScore.pl_acm) : "N/A"}`}
        </IonChip>
      </IonItem>
      <IonList className="ion-no-padding" slot="content">
        <div style={{ paddingLeft: "25px" }}>{children}</div>
      </IonList>
    </IonAccordion>
  );
};

export const PrjGroup: React.FC<{
  item: any;
  filledCount: { filled: number; all: number };
  lead?: string;
}> = ({ children, item, filledCount, lead }) => {
  return (
    <IonAccordion>
      <IonItem id="prj-header" lines="inset" slot="header">
        <IonLabel color="dark">
          <IonChip color="secondary" aria-disabled>
            {item.id}
          </IonChip>{" "}
          {item.name}
        </IonLabel>
        {lead && <EmpChip value={lead} />}
        <IonChip color="success" aria-disabled>
          {Math.round(item.total_storypoint * 100) / 100} Storypoint
        </IonChip>
        <IonChip color="primary" aria-disabled>
          {`${filledCount.filled}/${filledCount.all}`}
        </IonChip>
      </IonItem>
      <IonList className="ion-no-padding" slot="content">
        <div style={{ paddingLeft: "25px" }}>{children}</div>
      </IonList>
    </IonAccordion>
  );
};

export const EpicGroup: React.FC<{
  item: any;
  filledCount: { filled: number; all: number };
  icon: string;
  lead?: string;
}> = ({ children, item, filledCount, icon, lead }) => {
  return (
    <IonAccordion>
      <IonItem id="epic-header" lines="inset" slot="header">
        {item.name ? (
          <IonLabel color="dark">
            <IonChip color="tertiary" aria-disabled>
              {item.id}
            </IonChip>{" "}
            {item.name}
          </IonLabel>
        ) : (
          <IonLabel color="dark">
            <h2>{item.id}</h2>
          </IonLabel>
        )}
        {lead && <EmpChip value={lead} />}
        <IonChip color="success" aria-disabled>
          {Math.round(item.total_storypoint * 100) / 100} Storypoint
        </IonChip>
        <IonChip color="primary" aria-disabled>
          {`${filledCount.filled}/${filledCount.all}`}
        </IonChip>
      </IonItem>
      <IonList className="ion-no-padding" slot="content">
        <div style={{ paddingLeft: "25px" }}>{children}</div>
      </IonList>
    </IonAccordion>
  );
};

export const IndvKPIGroup: React.FC<{
  name: string;
  total_storypoint: number;
  ScoreForm: any;
}> = ({ children, name, total_storypoint, ScoreForm }) => {
  const [expand, setExpand] = useState<boolean>();

  const toggle = () => {
    setExpand(!expand);
  };
  return (
    <>
      <IonItem disabled={name === "None" ? true : false}>
        <IonGrid className="ion-no-margin ion-no-padding">
          <IonRow style={{ paddingBottom: "5px" }}>
            <IonCol size="12" size-lg="8">
              <p>
                <IonText style={{ fontWeight: "bold" }}>{name}</IonText>
                <IonChip color="success" aria-disabled>
                  {Math.round(total_storypoint * 100) / 100} Storypoint
                </IonChip>
              </p>
            </IonCol>
            <IonCol className="ion-align-self-center" size="12" size-lg="4">
              {ScoreForm}
            </IonCol>
          </IonRow>
        </IonGrid>
        <IonButtons slot="end">
          <IonButton onClick={toggle}>
            <IonIcon color="medium" icon={expand ? chevronUp : chevronDown} />
          </IonButton>
        </IonButtons>
      </IonItem>

      <Collapse in={expand} timeout="auto">
        <div style={{ paddingLeft: "25px" }}>{children}</div>
      </Collapse>
    </>
  );
};

export const IssueItem: React.FC<{
  issue_type: string;
  issue_id: string;
  issue_summary: string;
  issue_resolution: string;
  storypoint: number;
  emp_id?: string;
}> = ({ issue_type, issue_id, issue_summary, storypoint, emp_id }) => {
  const avatarEmp = useAppSelector(selectEmpAvatar(emp_id ? emp_id : ""));

  return (
    <>
      <IonItem lines="inset">
        {issue_type === "Task" ? (
          <IonIcon color="secondary" slot="start" icon={checkbox} />
        ) : issue_type === "Story" ? (
          <IonIcon color="success" slot="start" icon={bookmark} />
        ) : issue_type === "Bug" ? (
          <IonIcon color="danger" slot="start" icon={bug} />
        ) : (
          <IonIcon color="success" slot="start" icon={listCircle} />
        )}
        <p>
          <IonText style={{ fontWeight: "bold" }}>{issue_id} </IonText>
          <IonChip color="success" aria-disabled>
            {storypoint ? storypoint : 0} storypoint
          </IonChip>
          <small>{issue_summary}</small>
        </p>
        {emp_id && (
          <IonChip slot="end" color="secondary">
            <IonAvatar>
              <img src={avatarEmp} alt="avatar" />
            </IonAvatar>
            <IonLabel>{emp_id}</IonLabel>
          </IonChip>
        )}
      </IonItem>
    </>
  );
};

export const Footer: React.FC<{
  totalStorypoint?: number;
  filledCount?: { filled: number; all: number };
  updateScore?: Function;
  updateDate?: Date | null;
  buttonList?: Array<React.ReactElement>;
  editable: any;
}> = ({
  totalStorypoint,
  filledCount,
  updateScore,
  updateDate,
  editable,
  buttonList,
}) => {
  console.log(
    "filledCount",
    filledCount,
    "filledCount.all",
    filledCount?.all,
    "filledCount.filled",
    filledCount?.filled
  );
  return (
    <IonFooter>
      <IonToolbar className="ion-padding-horizontal">
        {totalStorypoint && (
          <>
            <IonLabel>{"Total Storypoint : "}</IonLabel>
            <IonChip color="success" aria-disabled>
              {Math.round(totalStorypoint * 100) / 100} Storypoint
            </IonChip>
          </>
        )}
        {filledCount && (
          <>
            <IonLabel>{"Evaluation : "}</IonLabel>
            <IonChip color="primary" aria-disabled>
              {`${filledCount.filled}/${filledCount.all}`}
            </IonChip>
          </>
        )}
        <IonLabel>
          {updateDate &&
            "Last updated : " +
              updateDate.toLocaleString("en-GB", {
                year: "2-digit",
                month: "short",
                day: "numeric",
                hour12: false,
                hour: "2-digit",
                minute: "2-digit",
              })}
        </IonLabel>
        <IonButtons color="primary" slot="end">
          {updateScore && (
            <IonButton
              aria-label="Save"
              onClick={(e) => updateScore(e)}
              disabled={!editable}
            >
              <IonIcon slot="icon-only" icon={save} />
            </IonButton>
          )}
          {buttonList?.map((item, i) => (
            <React.Fragment key={i}>{item}</React.Fragment>
          ))}
        </IonButtons>
      </IonToolbar>
    </IonFooter>
  );
};
