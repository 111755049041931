import {
  IonBackButton,
  IonButtons,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonItem,
  IonLoading,
  IonMenuButton,
  IonPage,
  IonRow,
  IonSearchbar,
  IonTitle,
  IonToast,
  IonToolbar,
} from "@ionic/react";
import { DepartmentAPI, ScoreWeightAPI } from "api/KPIAPI";
import Alert from "components/Alert";
import KPICiteriaModal from "components/Modal/KPICiteriaModal";
import { IScoreDepartmentData, IScoreWeightData } from "models/kpi.model";
import React, { useCallback, useEffect, useState, useMemo } from "react";
import { useHistory } from "react-router";
import { selectAuthen } from "redux/authen/selector";
import { selectDDLUserGroup } from "redux/dropdown/reducer";
import { useAppSelector } from "redux/hooks";
import { HasPermission } from "utils/auth.util";
import { Footer } from "../../../components/Item/ItemKPI";
import Spinning from "../../../components/Spinning";
import useKPI from "../../../store/KPIProvider";
import { roundTwoDigit } from "../../../utils";
import ExportModal from "./export_modal";
import List from "./list";

const calFinalScore = (
  eachScoreItem: IScoreDepartmentData,
  scoreweight: IScoreWeightData
) => {
  if (eachScoreItem.pip_acm) {
    const finalScore = roundTwoDigit(
      eachScoreItem.vp_acm * scoreweight.di1_ratio +
        ((eachScoreItem.di2_acm +
          eachScoreItem.di3_acm +
          eachScoreItem.di4_acm +
          eachScoreItem.di5_acm +
          eachScoreItem.di6_acm) /
          5) *
          (scoreweight.di2_ratio +
            scoreweight.di3_ratio +
            scoreweight.di4_ratio +
            scoreweight.di5_ratio +
            scoreweight.di6_ratio -
            scoreweight.pip_ratio) +
        eachScoreItem.e360_acm * scoreweight.keyfactor1_ratio +
        eachScoreItem.pip_acm * scoreweight.pip_ratio
    );
    return finalScore;
  }
  if (eachScoreItem.pms_acm) {
    const finalScore = roundTwoDigit(
      eachScoreItem.vp_acm * scoreweight.di1_ratio +
        ((eachScoreItem.di2_acm +
          eachScoreItem.di3_acm +
          eachScoreItem.di4_acm +
          eachScoreItem.di5_acm +
          eachScoreItem.di6_acm) /
          5) *
          (scoreweight.di2_ratio +
            scoreweight.di3_ratio +
            scoreweight.di4_ratio +
            scoreweight.di5_ratio +
            scoreweight.di6_ratio -
            scoreweight.pms_ratio) +
        eachScoreItem.e360_acm * scoreweight.keyfactor1_ratio +
        eachScoreItem.pip_acm * scoreweight.pms_ratio
    );
    return finalScore;
  }
  if (
    (eachScoreItem.e360_acm == null || eachScoreItem.e360_acm == 0) &&
    scoreweight.keyfactor1_ratio != 0
  ) {
    const finalScore = roundTwoDigit(
      eachScoreItem.vp_acm *
        (scoreweight.di1_ratio / (1 - scoreweight.keyfactor1_ratio)) +
        ((eachScoreItem.di2_acm +
          eachScoreItem.di3_acm +
          eachScoreItem.di4_acm +
          eachScoreItem.di5_acm +
          eachScoreItem.di6_acm) /
          5) *
          ((scoreweight.di2_ratio +
            scoreweight.di3_ratio +
            scoreweight.di4_ratio +
            scoreweight.di5_ratio) /
            (1 - scoreweight.keyfactor1_ratio))
    );
    return finalScore;
  } else {
    // const finalScore = roundTwoDigit(
    //   eachScoreItem.vp_acm * scoreweight.di1_ratio +
    //     ((eachScoreItem.di2_acm +
    //       eachScoreItem.di3_acm +
    //       eachScoreItem.di4_acm +
    //       eachScoreItem.di5_acm +
    //       eachScoreItem.di6_acm) /
    //       5) *
    //       (scoreweight.di2_ratio +
    //         scoreweight.di3_ratio +
    //         scoreweight.di4_ratio +
    //         scoreweight.di5_ratio +
    //         scoreweight.di6_ratio) +
    //     eachScoreItem.e360_acm * scoreweight.keyfactor1_ratio
    // );
    const finalScore = roundTwoDigit(
      eachScoreItem.vp_acm * scoreweight.di1_ratio +
        eachScoreItem.di2_acm * scoreweight.di2_ratio +
        eachScoreItem.di3_acm * scoreweight.di3_ratio +
        eachScoreItem.di4_acm * scoreweight.di4_ratio +
        eachScoreItem.di5_acm * scoreweight.di5_ratio +
        eachScoreItem.di6_acm * scoreweight.di6_ratio +
        eachScoreItem.e360_acm * scoreweight.keyfactor1_ratio
    );
    return finalScore;
  }
};

const Page: React.FC<{ title: string; path: string }> = ({ title, path }) => {
  const [Items, setItems] = useState<Array<IScoreDepartmentData>>([]);
  const [searchEmp, setSearchEmp] = useState<string>("");
  const [scoreWeight, setScoreWeight] = useState<any | null>(null);
  const [saveSuccess, setSaveSuccess] = useState<boolean>(false);
  const [Textloading, setTextLoading] = useState<boolean>(false);
  const [showLoading, setShowLoading] = useState<boolean>(false);
  const [update, setUpdate] = useState(0);
  const userGroupOption = useAppSelector(selectDDLUserGroup);
  const [userGroup, setUserGroup] = useState<string | null>(null);
  const history = useHistory();
  const [alert, setAlert] = useState<boolean>(false);
  const [textAlert, setTextAlert] = useState<string>("");
  const { user } = useAppSelector(selectAuthen);

  const finalScores = useMemo(() => {
    return Items.map((eachScore) => calFinalScore(eachScore, scoreWeight));
  }, [Items, scoreWeight]);

  const {
    state: { period },
  } = useKPI();

  const { permission } = useAppSelector(selectAuthen);

  useEffect(() => {
    if (period) {
      ScoreWeightAPI.read({ period: period.name }).then((res) => {
        setScoreWeight(res.data?.data ?? res.data);
      });
    } else history.replace(path);
  }, [period, history, path]);

  useEffect(() => {
    setTextLoading(true);
    if (period) {
      DepartmentAPI.read({ period: period.name, group: userGroup })
        .then((res) => {
          const { score_list } = res.data;
          /// get score emp
          setItems(
            score_list.map((data: any) => ({
              ...data,
              vp_acm: data.vp_acm ? data.vp_acm : roundTwoDigit(data.pm_acm),
            }))
          );
        })
        .then(() => setTextLoading(false));
    } else history.replace(path);
  }, [period, userGroup, update, history, path]);
  const countFilled = () => {
    const allfilled = Items.filter(
      (data) =>
        data.vp_acm !== 0 &&
        data.vp_acm !== null &&
        data.di2_acm !== 0 &&
        data.di2_acm !== null &&
        data.di3_acm !== 0 &&
        data.di3_acm !== null &&
        data.di4_acm !== 0 &&
        data.di4_acm !== null &&
        data.di5_acm !== 0 &&
        data.di5_acm !== null &&
        data.di6_acm !== 0 &&
        data.di6_acm !== null
    );
    return { filled: allfilled.length, all: Items.length };
  };

  const updateScore = (event: any) => {
    event.preventDefault();

    const data = Items.map((value, i) => {
      return {
        ...value,
        diff_ratio: roundTwoDigit(value.vp_acm / value.pm_acm),
        final_score: finalScores[i],
      };
    });

    setShowLoading(true);
    setTextLoading(true);
    DepartmentAPI.update(data, { period: period!.name })
      .then((item) => {
        if (Array.isArray(item.data)) {
        } else {
          console.log("failure");
        }
        setShowLoading(false);
        setTextLoading(false);
        setUpdate(update + 1);
        setSaveSuccess(true);
      })
      .catch((err) => {
        console.log("failure");
        setShowLoading(false);
        setTextLoading(false);
      });
  };

  const filterSearch = (list: Array<IScoreDepartmentData>, searchEmp: string) =>
    list.filter((emp) => emp.emp_id.indexOf(searchEmp.toLowerCase()) > -1);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color="gbdi">
          <IonButtons slot="start">
            <IonMenuButton></IonMenuButton>
            <IonBackButton text="" defaultHref="kpi/department" />
          </IonButtons>
          <IonTitle>{`${title} (${period?.name})`}</IonTitle>
        </IonToolbar>
        <IonToolbar>
          <IonGrid>
            <IonRow>
              <IonCol size="12" sizeSm="12">
                <IonItem lines="none">
                  <IonSearchbar
                    placeholder="Search Employee"
                    value={searchEmp}
                    onIonChange={(e) => {
                      setSearchEmp(e.detail.value!);
                    }}
                  ></IonSearchbar>
                </IonItem>
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        {Textloading ? (
          <Spinning />
        ) : (
          <div>
            {Boolean(Items.length) ? (
              <List
                Items={filterSearch(Items, searchEmp)}
                ScoreList={Items}
                setScoreList={setItems}
                countFilled={countFilled}
                period={period}
                finalScores={finalScores}
              />
            ) : (
              <h1 className="ion-text-center">No Record</h1>
            )}
          </div>
        )}
      </IonContent>

      <IonToast
        isOpen={saveSuccess}
        onDidDismiss={() => setSaveSuccess(false)}
        message="Your data was saved."
        duration={2000}
      />
      <IonLoading
        cssClass="my-custom-class"
        isOpen={showLoading}
        onDidDismiss={() => setShowLoading(false)}
        message={"Saving..."}
      />
      {Boolean(Items.length) && (
        <Footer
          filledCount={countFilled()}
          updateScore={updateScore}
          editable={period?.edit_department && user && user.role.includes("vp")}
          buttonList={[<ExportModal />, <KPICiteriaModal />]}
        />
      )}
      <Alert
        showAlert={alert}
        onDisDismiss={() => {
          setAlert(false);
        }}
        message={textAlert}
      />
    </IonPage>
  );
};

export default Page;
