import {
  IonAccordionGroup,
  IonChip,
  IonCol,
  IonGrid,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonRow,
  IonText,
} from "@ionic/react";
import { bookmark, bug, checkbox, flash, listCircle } from "ionicons/icons";
import React, { useState } from "react";
import { selectAuthen } from "redux/authen/selector";
import { useAppSelector } from "redux/hooks";
import {
  EpicGroup,
  IndvKPIGroup,
  PrjGroup,
} from "../../../components/Item/ItemKPI";
import { IPeriod } from "../../../models/kpi.model";
import { formatShortDate } from "utils";

const List: React.FC<{
  Items: Array<any>;
  ScoreList: Array<any>;
  setScoreList: Function;
  emp_id: string | null;
  countFilled: Function;
  period: IPeriod | null;
}> = ({ Items, ScoreList, setScoreList, emp_id, countFilled, period }) => {
  const changeScore = (e: any, detail: any) => {
    let index = ScoreList.findIndex(
      (score: any) =>
        score.emp_id === detail.emp_id &&
        score.prj_id === detail.prj_id &&
        score.epic_id === detail.epic_id &&
        score.depa_indv_group === detail.depa_indv_group &&
        score.period === detail.period
    );
    if (index !== -1) {
      setScoreList([
        ...ScoreList.slice(0, index),
        { ...ScoreList[index], self_acm: e },
        ...ScoreList.slice(index + 1),
      ]);
    }
  };

  const renderKPIList = Items.map((p) =>
    p.list.map((p: any) => {
      return (
        <PrjGroup
          key={p.id}
          item={p}
          lead={p.lead}
          filledCount={countFilled({ prj_id: p.id })}
        >
          {p.list.map((p: any) => {
            return (
              <EpicGroup
                key={p.id}
                item={p}
                icon={flash}
                filledCount={countFilled({ prj_id: p.prj_id, epic_id: p.id })}
                lead={p.epic_lead}
              >
                {p.list.map((p: any) => {
                  return (
                    <IndvKPIGroup
                      key={p.name}
                      name={p.name}
                      total_storypoint={p.total_storypoint}
                      ScoreForm={
                        <ScoreForm
                          p={p.detail}
                          emp={emp_id}
                          changeScore={changeScore}
                          editable={period?.edit_self}
                          score_acm={{
                            self_acm:
                              p.name !== "None"
                                ? ScoreList.find(
                                    (score: any) =>
                                      score.emp_id === p.detail.emp_id &&
                                      score.prj_id === p.detail.prj_id &&
                                      score.epic_id === p.detail.epic_id &&
                                      score.depa_indv_group ===
                                        p.detail.depa_indv_group &&
                                      score.period === p.detail.period
                                  )?.self_acm
                                : null,
                            pl_acm: p.pl_acm,
                            spm_acm: p.spm_acm,
                          }}
                        />
                      }
                    >
                      {p.task.map((task: any) => (
                        <IonItem
                          disabled={p.name === "None" ? true : false}
                          key={task.issue_id}
                        >
                          {task.issue_type === "Task" ? (
                            <IonIcon
                              color="secondary"
                              slot="start"
                              icon={checkbox}
                            />
                          ) : task.issue_type === "Story" ? (
                            <IonIcon
                              color="success"
                              slot="start"
                              icon={bookmark}
                            />
                          ) : task.issue_type === "Bug" ? (
                            <IonIcon color="danger" slot="start" icon={bug} />
                          ) : (
                            <IonIcon
                              color="success"
                              slot="start"
                              icon={listCircle}
                            />
                          )}
                          <p>
                            <IonText style={{ fontWeight: "bold" }}>
                              {task.issue_id}{" "}
                            </IonText>
                            <IonChip color="success" aria-disabled>
                              {task.storypoint ? task.storypoint : 0} storypoint
                            </IonChip>
                            <small>
                              {task.issue_summary}{" "}
                              <IonText color="orange">
                                {formatShortDate(task.issue_resolution)}
                              </IonText>
                            </small>
                          </p>
                        </IonItem>
                      ))}
                    </IndvKPIGroup>
                  );
                })}
              </EpicGroup>
            );
          })}
        </PrjGroup>
      );
    })
  );

  return (
    <div className="ion-align-self-center">
      {Boolean(Items.length) ? (
        <IonAccordionGroup multiple>{renderKPIList}</IonAccordionGroup>
      ) : (
        <h1 className="ion-text-center">No Record</h1>
      )}
    </div>
  );
};

const ScoreForm: React.FC<{
  changeScore: Function;
  editable: any;
  emp: string | null;
  score_acm: {
    self_acm: number | null;
    pl_acm: boolean | null;
    spm_acm: number | null;
  };
  p: any;
}> = ({ editable, emp, changeScore, score_acm, p }) => {
  const { user } = useAppSelector(selectAuthen);
  const [isEdit, setIsEdit] = useState("dark");
  const [username] = useState(user?.username);

  const checkEditable = (p: any) => {
    if (
      p.depa_indv_group === "None" ||
      p.prj_id === "LEAVE" ||
      p.prj_id === "IO" ||
      !editable ||
      (user && emp !== username)
    )
      return true;
    else return false;
  };

  return (
    <IonGrid className="ion-no-padding ion-no-margin">
      <IonRow>
        <IonCol size="6">
          <IonItem lines={checkEditable(p) ? "none" : "inset"}>
            <IonLabel position="stacked" color={isEdit}>
              {"Self"}
            </IonLabel>
            <IonInput
              type="number"
              name="self_acm"
              id="self_acm"
              max="5"
              min="0"
              step="0.5"
              color={isEdit}
              value={score_acm.self_acm}
              disabled={checkEditable(p)}
              onIonChange={(e: any) => {
                let score = parseFloat(e.detail.value!);
                if (score && (score > 5 || score < 0)) {
                  setIsEdit("danger");
                }
              }}
              onIonBlur={(e: any) => {
                let score = parseFloat(e.target.value!);
                if (score && (score > 5 || score < 0)) {
                  //setSelfACM(0);
                  setIsEdit("danger");
                  changeScore(0, p);
                } else {
                  //setSelfACM(score);
                  setIsEdit("dark");
                  changeScore(score ? score : null, p);
                }
              }}
              onWheel={(e: any) => {
                e.preventDefault();
                e.target.blur();
              }}
            />
          </IonItem>
        </IonCol>
        <IonCol size="6">
          <IonItem lines="none">
            <IonLabel position="stacked">{"PM"}</IonLabel>
            <IonInput
              name="pm_acm"
              id="pm_acm"
              disabled
              value={score_acm.pl_acm ? "*" : ""}
            />
          </IonItem>
        </IonCol>
      </IonRow>
    </IonGrid>
  );
};

export default List;
