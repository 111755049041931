import React, { useEffect, useState } from "react";
// import { modelData } from "./model";
import { ISettingPeriodData } from "models/kpi.model";
import {
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonLoading,
  IonMenuButton,
  IonPage,
  IonRow,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import Table from "./table";
import { useAppSelector } from "redux/hooks";
import { selectAuthen } from "redux/authen/selector";
import { PeriodSettingAPI } from "api/KPIAPI";
import { save } from "ionicons/icons";

const Page: React.FC = () => {
  const [Items, setItems] = React.useState<Array<ISettingPeriodData>>([]);
  const [showLoading, setShowLoading] = useState(false);
  const [action, setAction] = useState<Array<string>>([]);

  const { user } = useAppSelector(selectAuthen);

  useEffect(() => {
    const getItems = () => {
      setShowLoading(true);
      PeriodSettingAPI.read().then((items) => {
        setItems(items.data);
        setShowLoading(false);
      });
    };

    if (user) {
      const resource = user.resource.find(
        (element) => element.name === "kpi_setting"
      );
      if (resource) {
        setAction([...resource.action]);
      }
    }

    console.log("FETCH");
    getItems();
    console.log("KPI SETTING MOUNT");
    return () => {
      console.log("KPI SETTING UNMOUNT");
    };
  }, [user]);

  const handleSave = (event: any) => {
    event.preventDefault();
    setShowLoading(true);

    PeriodSettingAPI.update(setItems).then((item) => {
      if (Array.isArray(item.data)) {
      } else {
        console.log("failure");
      }
      setShowLoading(false);
    });
  };
  const handleClear = () => {
    PeriodSettingAPI.read().then((items) => {
      setItems(items.data);
    });
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color="primary">
          <IonButtons slot="start">
            <IonMenuButton></IonMenuButton>
          </IonButtons>
          <IonTitle>KPI Setting</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonLoading
        cssClass="my-custom-class"
        isOpen={showLoading}
        onDidDismiss={() => setShowLoading(false)}
        message={"Loading ..."}
        duration={0}
      />
      <IonContent>
        {!showLoading && (
          <>
            <Table
              Items={Items}
              updateItems={setItems}
              username={user?.username}
            />
          </>
        )}
      </IonContent>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: "10px",
        }}
      >
        <IonButton style={{ flex: 1 }} fill="clear" onClick={handleClear}>
          Clear
        </IonButton>
        <IonButton style={{ flex: 1 }} onClick={handleSave}>
          บันทึก
        </IonButton>
      </div>
    </IonPage>
  );
};

export default Page;
