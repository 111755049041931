//import { create, trash, addCircleOutline } from 'ionicons/icons';
import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonLoading,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToast,
  IonToolbar,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { selectAuthen } from "redux/authen/selector";
import { useAppSelector } from "redux/hooks";
import { Footer } from "../../../components/Item/ItemKPI";
import EmployeeSelect from "../../../components/Select/EmployeeSelect";
import Spinning from "../../../components/Spinning";
import useKPI from "../../../store/KPIProvider";
import { ProjectEpicAPI } from "api/KPIAPI";
import List from "./list";
import KPICiteriaModal from "components/Modal/KPICiteriaModal";

const Page: React.FC<{ title: string; path: string }> = ({ title, path }) => {
  const { user } = useAppSelector(selectAuthen);
  const {
    state: { period, emp_id },
    dispatch,
  } = useKPI();

  const [Items, setItems] = useState<Array<any>>([]);
  const [showLoading, setShowLoading] = useState<boolean>(false);
  const [showSaving, setShowSaving] = useState<boolean>(false);
  const [ScoreList, setScoreList] = useState<Array<any>>([]);
  const [totalStorypoint, setTotalStorypoint] = useState<number>(0);
  const [updateDate, setUpdateDate] = useState<Date | null>(null);
  const [saveSuccess, setSaveSuccess] = useState<boolean>(false);
  const [saveError, setSaveError] = useState<boolean>(false);
  const history = useHistory();

  useEffect(() => {
    setShowLoading(true);
    if (emp_id && period) {
      ProjectEpicAPI.read({ emp_id: emp_id, period: period.name }).then(
        (res) => {
          const { data, form, total_storypoint, updateDate } = res.data;
          setItems(data);
          setScoreList(form);
          setTotalStorypoint(total_storypoint ? total_storypoint : 0);
          setUpdateDate(new Date(updateDate));
          setShowLoading(false);
        }
      );
    } else history.replace(path);
  }, [emp_id, period, history, path]);

  function chunkData(data, maxChunkSize) {
    const dataArray: any[] = [];
    let index = 0;

    while (index < data.length || index < data.length) {
      const chunkedData = data.slice(index, index + maxChunkSize);
      dataArray.push(chunkedData);
      index += maxChunkSize;
    }

    return dataArray;
  }

  const updateScore = async (event: any) => {
    event.preventDefault();
    setShowLoading(true);
    setShowSaving(true);
    setSaveError(false);

    const chunked = chunkData(ScoreList, 50);
    const promises = chunked.map((chunk) => ProjectEpicAPI.update(chunk));
    const results = await Promise.all(promises);
    const anyError = results.some((res) => res.data?.message !== "success");
    setShowLoading(false);
    setShowSaving(false);
    if (anyError) {
      setSaveError(true);
    } else {
      setSaveSuccess(true);
    }

    // ProjectMemberAPI.update(ScoreList).then((item) => {
    //   console.log("👻 ~ ProjectMemberAPI.update ~ ScoreList:", ScoreList);
    //   if (Array.isArray(item.data)) {
    //   } else {
    //     setShowLoading(false);
    //     setShowSaving(false);
    //     setSaveSuccess(true);
    //   }
    // });
  };

  const countFilled = (filter: {
    prj_id?: string;
    epic_id?: string;
    emp_id?: string;
  }) => {
    const { prj_id, epic_id, emp_id } = filter;
    const allInput = ScoreList.filter(
      (data) =>
        (prj_id ? data.prj_id === prj_id : true) &&
        (epic_id ? data.epic_id === epic_id : true) &&
        (emp_id ? data.emp_id === emp_id : true) &&
        data.depa_indv_group !== "None"
    );
    const allfilled = ScoreList.filter(
      (data) =>
        (prj_id ? data.prj_id === prj_id : true) &&
        (epic_id ? data.epic_id === epic_id : true) &&
        (emp_id ? data.emp_id === emp_id : true) &&
        data.pl_acm !== 0 &&
        data.pl_acm !== null &&
        data.depa_indv_group !== "None"
    );
    return { filled: allfilled.length, all: allInput.length };
  };

  const calEmpScore = (filter: {
    prj_id?: string;
    epic_id?: string;
    emp_id?: string;
  }) => {
    const { prj_id, epic_id, emp_id } = filter;
    const allfilled = ScoreList.filter(
      (data) =>
        (prj_id ? data.prj_id === prj_id : true) &&
        (epic_id ? data.epic_id === epic_id : true) &&
        (emp_id ? data.emp_id === emp_id : true) &&
        data.pl_acm !== 0 &&
        data.pl_acm !== null &&
        data.depa_indv_group !== "None"
    );
    if (allfilled.length) {
      const sum = allfilled.reduce(
        (acc, c) => ({
          pl_acm: acc.pl_acm + c.pl_acm * c.total_storypoint,
          storypoint:
            acc.storypoint + (c.total_storypoint ? c.total_storypoint : 0),
        }),
        {
          pl_acm: 0,
          storypoint: 0,
        }
      );
      return {
        pl_acm: sum.pl_acm / sum.storypoint,
      };
    } else {
      return {
        pl_acm: null,
      };
    }
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color="gbdi">
          <IonButtons slot="start">
            <IonMenuButton></IonMenuButton>
            <IonBackButton text="" defaultHref="/kpi/pm" />
          </IonButtons>
          <IonTitle>{`${title} (${period?.name})`}</IonTitle>
        </IonToolbar>
        {user &&
          Boolean(user.role.includes("admin") || user.role.includes("spm")) && (
            <IonToolbar>
              <EmployeeSelect
                label="Employee"
                value={emp_id}
                name="Employee"
                onChange={(e: any) => {
                  dispatch({ type: "SET_EMP", emp_id: e.target.value });
                }}
              />
            </IonToolbar>
          )}
      </IonHeader>
      <IonContent>
        {showLoading ? (
          <Spinning></Spinning>
        ) : (
          <List
            Items={Items}
            ScoreList={ScoreList}
            setScoreList={setScoreList}
            emp_id={emp_id}
            countFilled={countFilled}
            calEmpScore={calEmpScore}
            period={period}
          />
        )}
      </IonContent>
      {!showLoading && Boolean(Items.length) && (
        <Footer
          totalStorypoint={totalStorypoint}
          filledCount={countFilled({})}
          updateScore={updateScore}
          updateDate={updateDate}
          editable={period?.edit_pl && user && emp_id === user.username}
          buttonList={[<KPICiteriaModal />]}
        />
      )}
      <IonToast
        isOpen={saveSuccess}
        onDidDismiss={() => setSaveSuccess(false)}
        message="Your data was saved."
        duration={2000}
      />
      <IonToast
        isOpen={saveError}
        onDidDismiss={() => setSaveError(false)}
        message="Your data was Error."
        duration={2000}
      />
      <IonLoading
        cssClass="my-custom-class"
        isOpen={showSaving}
        message={"Saving ..."}
        duration={0}
      />
    </IonPage>
  );
};

export default Page;
