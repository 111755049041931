import {
  IonButtons,
  IonContent,
  IonHeader,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import React from "react";
import { selectAuthen } from "redux/authen/selector";
import { useAppSelector } from "redux/hooks";

const Home: React.FC = () => {
  const { user } = useAppSelector(selectAuthen);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color="primary">
          <IonButtons slot="start">
            <IonMenuButton></IonMenuButton>
          </IonButtons>
          <IonTitle>Home</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <img src="assets/img/evaluation_process_2025_1.jpg" />
        <img src="assets/img/evaluation.jpg" />
        <div className="ion-padding">
          <h1>Welcome</h1>
          <h3>{user?.firstname}</h3>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Home;
