import {
  IonAccordionGroup,
  IonChip,
  IonCol,
  IonContent,
  IonGrid,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonRow,
  IonText,
  IonTextarea,
} from "@ionic/react";
import { bookmark, bug, checkbox, flash, listCircle } from "ionicons/icons";
import React, { useEffect, useRef, useState } from "react";
import { selectAuthen } from "redux/authen/selector";
import { useAppSelector } from "redux/hooks";
import { formatShortDate } from "utils";
import {
  EmpGroup,
  EpicGroup,
  IndvKPIGroup,
  PrjGroup,
} from "../../../components/Item/ItemKPI";
import { IPeriod } from "../../../models/kpi.model";

const List: React.FC<{
  Items: Array<any>;
  ScoreList: Array<any>;
  setScoreList: Function;
  emp_id: string | null;
  countFilled: Function;
  calEmpScore: Function;
  period: IPeriod | null;
}> = ({
  Items,
  ScoreList,
  setScoreList,
  emp_id,
  countFilled,
  calEmpScore,
  period,
}) => {
  const changeScore = (e: any, detail: any, scorename = "pl_acm") => {
    let index = ScoreList.findIndex(
      (score: any) =>
        score.emp_id === detail.emp_id &&
        score.prj_id === detail.prj_id &&
        score.epic_id === detail.epic_id &&
        score.depa_indv_group === detail.depa_indv_group &&
        score.period === detail.period
    );
    if (index !== -1) {
      setScoreList([
        ...ScoreList.slice(0, index),
        { ...ScoreList[index], [scorename]: e },
        ...ScoreList.slice(index + 1),
      ]);
    }
  };

  const renderKPIList = Items.map((p) => (
    <PrjGroup key={p.id} item={p} filledCount={countFilled({ prj_id: p.id })}>
      {p.list.map((p: any) => (
        <EpicGroup
          key={p.id}
          item={p}
          icon={flash}
          filledCount={countFilled({ prj_id: p.prj_id, epic_id: p.id })}
        >
          {p.list.map((p: any, i: any) => (
            <EmpGroup
              key={i}
              item={p}
              filledCount={countFilled({
                emp_id: p.emp_id,
                prj_id: p.prj_id,
                epic_id: p.epic_id,
              })}
              empScore={calEmpScore({
                emp_id: p.emp_id,
                prj_id: p.prj_id,
                epic_id: p.epic_id,
              })}
            >
              {p.list.map((p: any) => {
                const p_score =
                  p.name !== "None"
                    ? ScoreList.find(
                        (score: any) =>
                          score.emp_id === p.detail.emp_id &&
                          score.prj_id === p.detail.prj_id &&
                          score.epic_id === p.detail.epic_id &&
                          score.depa_indv_group === p.detail.depa_indv_group &&
                          score.period === p.detail.period
                      )
                    : null;
                return (
                  <IndvKPIGroup
                    key={p.name}
                    name={p.name}
                    total_storypoint={p.total_storypoint}
                    ScoreForm={
                      <ScoreForm
                        p={p.detail}
                        emp={emp_id}
                        changeScore={changeScore}
                        editable={period?.edit_pl}
                        score_acm={{
                          self_acm: p.self_acm,
                          pl_acm: p_score?.pl_acm,

                          spm_acm: p.spm_acm,
                          pl_comment: p_score?.pl_comment,
                        }}
                      />
                    }
                  >
                    {p.task.map((task: any) => (
                      <IonItem
                        disabled={p.name === "None" ? true : false}
                        key={task.issue_id}
                      >
                        {task.issue_type === "Task" ? (
                          <IonIcon
                            color="secondary"
                            slot="start"
                            icon={checkbox}
                          />
                        ) : task.issue_type === "Story" ? (
                          <IonIcon
                            color="success"
                            slot="start"
                            icon={bookmark}
                          />
                        ) : task.issue_type === "Bug" ? (
                          <IonIcon color="danger" slot="start" icon={bug} />
                        ) : (
                          <IonIcon
                            color="success"
                            slot="start"
                            icon={listCircle}
                          />
                        )}
                        <p>
                          <IonText style={{ fontWeight: "bold" }}>
                            {task.issue_id}{" "}
                          </IonText>
                          <IonChip color="success" aria-disabled>
                            {task.storypoint ? task.storypoint : 0} storypoint
                          </IonChip>
                          <small>
                            {task.issue_summary}{" "}
                            <IonText color="orange">
                              {formatShortDate(task.issue_resolution)}
                            </IonText>
                          </small>
                        </p>
                      </IonItem>
                    ))}
                  </IndvKPIGroup>
                );
              })}
            </EmpGroup>
          ))}
        </EpicGroup>
      ))}
    </PrjGroup>
  ));

  return (
    <>
      <IonContent>
        <div className="ion-align-self-center">
          {Boolean(Items.length) ? (
            <IonAccordionGroup multiple>{renderKPIList}</IonAccordionGroup>
          ) : (
            <h1 className="ion-text-center">No Record</h1>
          )}
        </div>
      </IonContent>
    </>
  );
};

const ScoreForm: React.FC<{
  changeScore: Function;
  editable: any;
  p: any;
  emp: string | null;
  score_acm: {
    self_acm: number | null;
    pl_acm: number | null;
    spm_acm: number | null;
    pl_comment: string | null;
  };
}> = ({ changeScore, editable, p, emp, score_acm }) => {
  const { user } = useAppSelector(selectAuthen);
  const [isEdit, setIsEdit] = useState("dark");
  // const [isReason, setIsReason] = useState<boolean>(false);
  const [username] = useState(user?.username);
  //const [plACM, setPlACM] = useState<number | null>(p.pl_acm);
  const checkEditable = (p: any) => {
    if (
      p.depa_indv_group === "None" ||
      p.prj_id === "LEAVE" ||
      p.prj_id === "IO" ||
      !editable ||
      (user && emp !== username)
    )
      return true;
    else return false;
  };
  // useEffect(() => {
  //   setIsReason(true);
  // }, []);

  return (
    <IonGrid className="ion-no-padding ion-no-margin">
      <IonRow>
        <IonCol size="6">
          <IonItem lines="none">
            <IonLabel position="stacked">{"Self"}</IonLabel>
            <IonInput
              type="number"
              name="self_acm"
              id="self_acm"
              max="5"
              min="0"
              step="0.5"
              disabled
              value={score_acm.self_acm}
            />
          </IonItem>
        </IonCol>
        <IonCol size="6">
          <IonItem lines={checkEditable(p) ? "none" : "inset"}>
            <IonLabel position="stacked" color={isEdit}>
              {"PM"}
            </IonLabel>
            <IonInput
              type="number"
              name="pl_acm"
              id="pl_acm"
              max="5"
              min="0"
              step="0.5"
              color={isEdit}
              value={score_acm.pl_acm}
              disabled={checkEditable(p)}
              onIonChange={(e: any) => {
                if (e.detail.value === undefined) return;
                // let score = parseFloat(e.detail.value!);
                // console.log("🚀 ~ score:", score);
                // if (score && score >= 4) {
                //   setIsReason(true);
                //   console.log("🚀 ~ score > 5 || score < 0:", score);
                // }
              }}
              onIonBlur={(e: any) => {
                let score = parseFloat(e.target.value!);

                if (score && (score > 5 || score < 0)) {
                  setIsEdit("danger");
                  //setPlACM(0);
                  changeScore(0, p);
                } else {
                  //setPlACM(score);
                  setIsEdit("dark");
                  changeScore(score ? score : null, p);
                }
              }}
              onWheel={(e: any) => {
                e.preventDefault();
                e.target.blur();
              }}
            />
          </IonItem>
        </IonCol>
      </IonRow>
      {score_acm.pl_acm && score_acm.pl_acm >= 4.5 && (
        <IonRow>
          <IonCol size="12">
            <IonItem lines="inset">
              <IonLabel position="stacked" color="danger">
                {"Reason *"}
              </IonLabel>
              <IonTextarea
                required
                autoGrow
                value={score_acm.pl_comment}
                onIonBlur={(e: any) => {
                  changeScore(e.target.value!, p, "pl_comment");
                }}
              ></IonTextarea>
            </IonItem>
          </IonCol>
        </IonRow>
      )}
    </IonGrid>
  );
};

export default List;
